import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Settings } from 'component/new_design/partial/SiteDetails/Advanced/Settings';
import { SSHKeysTable } from 'component/new_design/partial/tables/advanced/SSHKeysTable';
import ActionCard from 'component/new_design/partial/ActionCard';
import { useState } from 'react';
import { ConfirmationDialog } from 'component/new_design/base/dialogs/ConfirmationDialog';
import { SiteDetail, useDeleteSite } from 'api/site';
import { useNavigate } from 'react-router-dom';
import { linkHelper } from 'linkHelper';
import { useSnackbar } from 'component/hooks/useSnackbar';
import DeleteIcon from 'component/new_design/icons/Delete.svg?react';
import { RoleGuard } from 'component/base/RoleGuard';

interface AdvancedProps {
  readonly siteId: string;
}

const Advanced = ({ siteId }: AdvancedProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const deleteSite = useDeleteSite(siteId);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDeleteSiteConfirmed = async () => {
    await deleteSite.mutateAsync();
    enqueueSnackbar(t('site_deleted_successfully'));
    navigate(linkHelper.newDesign.sites.getLink());
  };

  return (
    <Stack gap={4}>
      <Settings siteId={siteId} />
      <RoleGuard roles={['billing_admin']} type="block">
        <SSHKeysTable siteId={siteId} />
        <ActionCard
          title={t('delete_website')}
          description={t('this_action_is_irreversible')}
          paletteColor="reds"
          icon={<DeleteIcon />}
          actions={
            <Button color="error" variant="contained" onClick={() => setIsDeleteDialogOpen(true)}>
              {t('delete_site')}
            </Button>
          }
        />
        {isDeleteDialogOpen ? (
          <ConfirmationDialog
            title={t('delete_site')}
            description={t('this_action_is_irreversible')}
            onConfirm={handleDeleteSiteConfirmed}
            onClose={() => setIsDeleteDialogOpen(false)}
            confirmText={t('delete')}
            confirmColor="error"
          />
        ) : null}
      </RoleGuard>
    </Stack>
  );
};

export default Advanced;
