import { Box, Button, Card, CardHeader, Skeleton, Stack, Typography } from '@mui/material';
import { CardList } from 'component/new_design/base/CardList';
import { IconWrapper } from 'component/new_design/base/IconWrapper';
import { linkHelper } from 'linkHelper';
import { useTranslation } from 'react-i18next';
import { Select } from 'component/new_design/base/forms/Select';
import { LoadingSwitch } from 'component/new_design/base/LoadingSwitch';
import {
  UpdateSiteSettingsRequest,
  useGetSiteSettings,
  useSiteDetails,
  useSiteSetActivityLoggingState,
  useUpdateSettings,
} from 'api/site';
import { useEffect, useRef, useState } from 'react';
import { WPCLIDialog } from './WPCLIDialog';
import { useRoleGuard } from 'component/hooks/useRoleGuard';
import { useSnackbar } from 'component/hooks/useSnackbar';

// icons
import ChevronRight from 'component/new_design/icons/ChevronRight.svg?react';
import DatabaseIcon from 'component/new_design/icons/Database.svg?react';
import FileCodeIcon from 'component/new_design/icons/FileCode.svg?react';
import ScriptIcon from 'component/new_design/icons/Script.svg?react';
import CloudArrowUploadIcon from 'component/new_design/icons/CloudArrowUpload.svg?react';
import QueueIcon from 'component/new_design/icons/Queue.svg?react';
import AutomationIcon from 'component/new_design/icons/Automation.svg?react';
import CodeIcon from 'component/new_design/icons/Code.svg?react';
import ActivityIcon from 'component/new_design/icons/Activity.svg?react';
import LockIcon from 'component/new_design/icons/LockClose.svg?react';
import { getSiteLockedStatus } from 'utils/site';
import { useDisableLockSite, useEnableLockSite } from 'api/security';

interface SettingsProps {
  readonly siteId: string;
}

const AVAILABLE_PHP_VERSIONS = ['5.6', '7.0', '7.2', '7.3', '7.4', '8.0', '8.1', '8.2', '8.3'];

export const Settings = ({ siteId }: SettingsProps) => {
  const { t } = useTranslation();
  const { data: siteDetailsData } = useSiteDetails({ id: siteId });

  const [isWpCliDialogOpen, setIsWpCliDialogOpen] = useState(false);
  const [updatingSetting, setUpdatingSetting] = useState<keyof UpdateSiteSettingsRequest | null>(
    null
  );

  const settings = useGetSiteSettings(siteId);
  const updateSettings = useUpdateSettings(siteId);
  const setActivityLoggingState = useSiteSetActivityLoggingState(siteId);
  const settingsResult = settings.data?.data.result;
  const { mutateAsync: disableLockSite } = useDisableLockSite({ siteId });
  const { mutateAsync: enableLockSite } = useEnableLockSite({ siteId });
  const isSiteLocked = getSiteLockedStatus(siteDetailsData?.data.result);
  const { enqueueSnackbar } = useSnackbar();
  const billingAdminBlock = useRoleGuard(['billing_admin'], 'block');
  const lockSiteRef = useRef<HTMLDivElement>(null);
  const hash = window.location.hash;

  useEffect(() => {
    if (window.location.hash === '#lock-site') {
      if (lockSiteRef.current) {
        const y = lockSiteRef.current?.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [hash]);

  const handleSettingsUpdate =
    (key: keyof UpdateSiteSettingsRequest) => async (value: string | number | boolean) => {
      let convertedValue: string | number;
      if (typeof value === 'boolean') {
        convertedValue = value ? 1 : 0;
      } else {
        convertedValue = value;
      }
      setUpdatingSetting(key);
      try {
        await updateSettings.mutateAsync({ [key]: convertedValue });
      } finally {
        setUpdatingSetting(null);
      }
    };

  const handleActivityLoggingUpdate = async (value: boolean) => {
    await setActivityLoggingState.mutateAsync(value);
  };

  const handleSiteLockUpdate = async (value: boolean) => {
    value ? await enableLockSite() : await disableLockSite();

    enqueueSnackbar(value ? t('site_locked_successfully') : t('site_unlocked_successfully'), {
      key: value ? 'siteLockSuccess' : 'siteUnlockSuccess',
      variant: 'success',
    });
  };

  const items = [
    billingAdminBlock
      ? null
      : {
          Icon: DatabaseIcon,
          id: t('database_management'),
          description: t('database_management_description'),
          action: (
            <Button
              color="secondary"
              variant="contained"
              href={linkHelper.managePhpadmin.getLink({ siteId })}
              target="_blank"
              endIcon={
                <IconWrapper size="xs">
                  <ChevronRight />
                </IconWrapper>
              }
            >
              {t('phpmyadmin')}
            </Button>
          ),
        },
    billingAdminBlock
      ? null
      : {
          Icon: FileCodeIcon,
          id: t('wp_command_line_interface'),
          description: t('wp_command_line_interface_description'),
          action: (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setIsWpCliDialogOpen(true)}
              endIcon={
                <IconWrapper size="xs">
                  <ChevronRight />
                </IconWrapper>
              }
            >
              WP-CLI
            </Button>
          ),
        },
    {
      Icon: ScriptIcon,
      id: t('php_version'),
      description: t('php_version_description'),
      action: (
        <Select
          aria-label={t('php_version')}
          value={settingsResult?.current_php_version}
          options={AVAILABLE_PHP_VERSIONS.map(version => ({ value: version, label: version }))}
          onChange={handleSettingsUpdate('new_php_version')}
          renderValue={value => <Box sx={{ minWidth: '110px' }}>PHP {value}</Box>}
          loading={updatingSetting === 'new_php_version'}
          disabled={billingAdminBlock}
        />
      ),
    },
    {
      Icon: CloudArrowUploadIcon,
      id: t('auto_update_wordpress_core'),
      description: t('auto_update_wordpress_core_description'),
      action: (
        <Select
          aria-label={t('auto_update_wordpress_core')}
          value={settingsResult?.wp_core_auto_updates}
          options={[
            { label: t('off'), value: 0 },
            { label: t('latest_version'), value: 1 },
            { label: t('minor_versions'), value: 2 },
          ]}
          onChange={handleSettingsUpdate('wp_core_auto_updates')}
          loading={updatingSetting === 'wp_core_auto_updates'}
          disabled={billingAdminBlock}
        />
      ),
    },
    {
      Icon: QueueIcon,
      id: t('auto_update_themes'),
      description: t('auto_update_themes_description'),
      action: (
        <LoadingSwitch
          aria-label={t('auto_update_themes')}
          checked={Boolean(settingsResult?.wp_theme_auto_updates)}
          onChange={handleSettingsUpdate('wp_theme_auto_updates')}
          disabled={billingAdminBlock}
        />
      ),
    },
    {
      Icon: AutomationIcon,
      id: t('auto_update_plugins'),
      description: t('auto_update_plugins_description'),
      action: (
        <LoadingSwitch
          aria-label={t('auto_update_plugins')}
          checked={Boolean(settingsResult?.wp_plugin_auto_updates)}
          onChange={handleSettingsUpdate('wp_plugin_auto_updates')}
          disabled={billingAdminBlock}
        />
      ),
    },
    {
      Icon: CodeIcon,
      id: t('ssh_access'),
      description: t('ssh_access_description'),
      action: (
        <LoadingSwitch
          aria-label={t('ssh_access')}
          checked={Boolean(settingsResult?.ssh_access)}
          onChange={handleSettingsUpdate('ssh_access')}
          disabled={billingAdminBlock}
        />
      ),
    },
    {
      Icon: ActivityIcon,
      id: t('activity_log'),
      description: t('activity_log_description'),
      action: (
        <LoadingSwitch
          aria-label={t('activity_log')}
          checked={Boolean(settingsResult?.wp_activity_log)}
          onChange={handleActivityLoggingUpdate}
          disabled={billingAdminBlock}
        />
      ),
    },
    {
      Icon: LockIcon,
      id: t('lock_site'),
      description: t('lock_site_description'),
      action: (
        <LoadingSwitch
          aria-label={t('lock_site')}
          checked={isSiteLocked}
          onChange={handleSiteLockUpdate}
          disabled={billingAdminBlock}
        />
      ),
    },
  ];

  const filteredItems = items.filter(Boolean) as NonNullable<(typeof items)[number]>[];

  return (
    <>
      <Card component="section">
        <CardHeader title={t('site_activity')} />
        <CardList
          items={filteredItems}
          renderItem={({ id, description, Icon }) => (
            <Stack ref={lockSiteRef} key={id} direction="row" alignItems="center" spacing={4}>
              <IconWrapper color="greys.500">
                <Icon />
              </IconWrapper>
              <Stack gap={1}>
                <Typography variant="body2">{id}</Typography>
                <Typography variant="body2" color="greys.500">
                  {description}
                </Typography>
              </Stack>
            </Stack>
          )}
          renderAction={item =>
            settings.isLoading ? <Skeleton width={100} height={36} /> : item.action
          }
        />
      </Card>
      {isWpCliDialogOpen ? (
        <WPCLIDialog siteId={siteId} onClose={() => setIsWpCliDialogOpen(false)} />
      ) : null}
    </>
  );
};
